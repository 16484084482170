var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';
import { useAppSelector } from '@store/Hooks';
import { closeSidebar, } from '@entities/current-user';
import { useCreateSubscriptionWithCheckoutMutation, useDisableSubscriptionMutation, useGetCurrentSubscriptionsQuery, } from '@shared/api/subscription';
import { useWizard } from '@shared/ui/misc/Wizard';
import useAppNavigation from '@utils/hooks/useAppNavigation';
const useSubscriptionChange = () => {
    const { user } = useAppSelector(state => state.user);
    const { paymentMethodFormFields } = useAppSelector(state => state.user);
    const { currentPlan, newPlan, currentSubscription, } = paymentMethodFormFields; // TODO: remove casting, check against type instead
    const { current, goNext, goTo } = useWizard();
    const dispatch = useDispatch();
    const { navigate, routes } = useAppNavigation();
    const { data: currentSubscriptions, } = useGetCurrentSubscriptionsQuery({
        userId: (user === null || user === void 0 ? void 0 : user.id) || '',
    }, {
        skip: !user,
    });
    const [createSubscriptionReq, { isLoading: isCreating, }] = useCreateSubscriptionWithCheckoutMutation();
    const [disableSubscriptionReq, { isLoading: isDisabling, }] = useDisableSubscriptionMutation();
    const isUpgradeStrategy = useMemo(() => {
        const currentPlanFee = Number(currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.unitAmount) || 0;
        const newPlanFee = Number(newPlan === null || newPlan === void 0 ? void 0 : newPlan.unitAmount) || 0;
        return newPlanFee > currentPlanFee;
    }, [
        currentPlan,
        newPlan,
    ]);
    const handleClose = () => {
        dispatch(closeSidebar());
        // this is used to remove url params with payment status
        navigate(routes.SETTINGS_SUBSCRIPTION);
    };
    const navigateToCheckoutUrl = (url) => {
        window.location.replace(url);
    };
    const cancelCurrentSubscription = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!user) {
            return false;
        }
        if (!currentSubscription) {
            return true;
        }
        try {
            const response = yield disableSubscriptionReq({
                userId: user.id,
                subscriptionId: currentSubscription.id,
            });
            return !!response.data;
        }
        catch (err) {
            return false;
        }
    });
    const subscriptionCheckout = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!user) {
            goTo(2);
            return;
        }
        try {
            const response = yield createSubscriptionReq({
                priceId: newPlan.id,
                userId: user.id
            });
            if (response.data) {
                const event = response
                    .data;
                navigateToCheckoutUrl(event.url);
            }
            // eslint-disable-next-line no-empty
        }
        catch (err) { }
    });
    const changeSubscriptionPlan = () => __awaiter(void 0, void 0, void 0, function* () {
        // Starter plan is not handled on backend as subscription,
        // Starter plan is acting as default if no subscription found on backend
        if (newPlan.name === 'STARTER') {
            const cancelCurrentSubscriptionSuccess = yield cancelCurrentSubscription();
            if (cancelCurrentSubscriptionSuccess) {
                goNext();
            }
            else {
                goTo(2);
            }
        }
        else if (currentPlan !== newPlan) {
            if (currentSubscriptions) {
                const checkoutingSubscription = currentSubscriptions
                    .find(s => s.status === 'CHECKOUTING' && s.platformType === newPlan.name);
                if (checkoutingSubscription) {
                    navigateToCheckoutUrl(checkoutingSubscription.url);
                    return;
                }
            }
            const cancelCurrentSubscriptionSuccess = yield cancelCurrentSubscription();
            if (cancelCurrentSubscriptionSuccess) {
                subscriptionCheckout();
            }
            else {
                goTo(2);
            }
        }
        else {
            goTo(2);
        }
    });
    const goToNextStep = () => {
        switch (current) {
            case 0:
                changeSubscriptionPlan();
                break;
            case 1:
                handleClose();
                break;
            case 2:
                handleClose();
                break;
            default:
                break;
        }
    };
    return {
        maskedCard: (paymentMethodFormFields === null || paymentMethodFormFields === void 0 ? void 0 : paymentMethodFormFields.cardNumber) || '**89',
        paymentMethodFormFields: paymentMethodFormFields,
        goToNextStep,
        isCreating,
        isDisabling,
        currentPlan,
        newPlan,
        currentSubscription,
        isUpgradeStrategy,
    };
};
export default useSubscriptionChange;
