const SIDEBAR_INNER_PROPS = {
    0: { displayCancelBtn: true },
    1: { displayCancelBtn: false, showTitle: false },
};
var AddPaymentSteps;
(function (AddPaymentSteps) {
    AddPaymentSteps[AddPaymentSteps["Initial"] = 0] = "Initial";
    AddPaymentSteps[AddPaymentSteps["Final"] = 1] = "Final";
})(AddPaymentSteps || (AddPaymentSteps = {}));
export { SIDEBAR_INNER_PROPS, AddPaymentSteps };
