import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { Banner } from '@shared/ui/sidebars/shared';
import Icon from '@shared/ui/icons/Icon';
import { ReactComponent as InfoIcon } from '@icons/wolfkit-solid/info-circle-solid.svg';
import { ReactComponent as AlertIcon } from '@icons/wolfkit-solid/alert-triangle-solid.svg';
import useSubscriptionChange from '../../../../useSubscriptionChange';
const BannerTitle = styled.span(props => ({
    display: 'block',
    fontWeight: 600,
    marginBottom: props.theme.spacing_sizes.xs * 0.25,
}));
const BannerText = styled.span(() => ({
    fontWeight: 400,
}));
const WarningBanner = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { isUpgradeStrategy } = useSubscriptionChange();
    return (_jsx(Banner, { icon: (_jsx(Icon, { IconComponent: isUpgradeStrategy ? InfoIcon : AlertIcon, color: isUpgradeStrategy ? theme.palette.primary.main : theme.palette.warning.main, size: 20 })), text: (_jsxs(_Fragment, { children: [_jsx(BannerTitle, { children: isUpgradeStrategy ?
                        t('settings.sidebars.change_subscription.steps.initial.upgrade_hint.title') :
                        t('settings.sidebars.change_subscription.steps.initial.downgrade_hint.title') }), _jsx(BannerText, { children: isUpgradeStrategy ?
                        t('settings.sidebars.change_subscription.steps.initial.upgrade_hint.text') :
                        t('settings.sidebars.change_subscription.steps.initial.downgrade_hint.text') })] })) }));
};
export default WarningBanner;
