import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Button from '@shared/ui/buttons/Button';
import IconWithText from '@shared/ui/display/IconWIthText';
import SuccessIcon from '@shared/ui/icons/SuccessIcon';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import { StepContainer } from '@shared/ui/sidebars/shared';
import useSubscriptionChange from '../useSubscriptionChange';
const Success = () => {
    const { t } = useTranslation();
    const { goToNextStep } = useSubscriptionChange();
    return (_jsx(SidebarInner, { children: _jsx(StepContainer, { content: (_jsx(IconWithText, { IconElement: _jsx(SuccessIcon, { size: 70 }), text: t('settings.sidebars.change_subscription.steps.success.title'), textMaxWidth: 330, textLineHeight: '36px' })), buttons: (_jsx(Button, { size: 'large', fullWidth: true, onClick: () => goToNextStep(), children: t('settings.sidebars.change_subscription.steps.success.continue_btn') })) }) }));
};
export default Success;
