import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import { StepContainer } from '@shared/ui/sidebars/shared';
import Button from '@shared/ui/buttons/Button';
import ChangePlanDetails from './components/ChangePlanDetails';
import useSubscriptionChange from '../../useSubscriptionChange';
import Title from './components/Title';
import WarningBanner from './components/WarningBanner';
const Initial = ({ onClose }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { goToNextStep, isCreating, isDisabling, } = useSubscriptionChange();
    return (_jsx(SidebarInner, { cancelBtnProps: { onClick: onClose }, displayCancelBtn: true, children: _jsx(StepContainer, { title: (_jsx(Title, {})), content: (_jsx(ChangePlanDetails, {})), buttons: (_jsxs(_Fragment, { children: [_jsx(WarningBanner, {}), _jsx(Button, { size: 'large', fullWidth: true, onClick: goToNextStep, disabled: isCreating || isDisabling, isLoading: isCreating || isDisabling, children: t('settings.sidebars.change_subscription.steps.initial.continue_btn') })] })), contentFlex: 1, buttonsGap: theme.spacing_sizes.l, containerPaddingTop: theme.spacing_sizes.xs * 3.25, containerGap: theme.spacing_sizes.xs * 3.25 }) }));
};
export default Initial;
