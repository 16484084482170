import { jsx as _jsx } from "react/jsx-runtime";
import { Trans, useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { StepTitle } from '@shared/ui/sidebars/shared';
import useSubscriptionChange from '../../../../useSubscriptionChange';
const StepTitleStyled = styled(StepTitle)(() => ({
    fontWeight: 400,
}));
const PlanName = styled.span(() => ({
    fontWeight: 600,
}));
const Title = () => {
    var _a;
    const { i18n } = useTranslation();
    const { newPlan, isUpgradeStrategy, } = useSubscriptionChange();
    return (_jsx(StepTitleStyled, { children: Trans({
            ns: 'translation',
            i18nKey: isUpgradeStrategy ? 'settings.sidebars.change_subscription.steps.initial.upgrade_title' :
                'settings.sidebars.change_subscription.steps.initial.downgrade_title',
            components: [
                (_jsx(PlanName, {}, 'plan-name')),
            ],
            values: {
                tarifName: (_a = newPlan.metadata) === null || _a === void 0 ? void 0 : _a.title[i18n.language],
            },
        }) }));
};
export default Title;
